<template>
    <div :style="loadingToken ? 'display: flex; justify-content: center' : ''">
        <v-progress-circular style="margin-top: 2rem" v-if="loadingToken" :size="30" :width="4" color="black" indeterminate></v-progress-circular>
        <div v-else style="display: flex; justify-content: center" :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'phone' : ''">
            <template v-if="error == 401">
                <div class="cntGallery token">
                    <p>
                        <strong
                            >Se ti compare questa schermata vuol dire che il codice di accesso (token) per caricare e vedere le foto è scaduto. Per generarne uno nuovo fare i seguenti passaggi (il
                            token ha una validità di 4 ore):</strong
                        >
                    </p>
                    <v-text-field ref="token" class="textfield" :rules="rules.required" v-model="token" label="Inserire Token" outlined clearable></v-text-field>
                    <div class="passaggiToken">
                        <div class="passaggi">
                            <ul>
                                <li>
                                    Fare click al seguente
                                    <a
                                        href="https://www.dropbox.com/oauth2/authorize?response_type=token&redirect_uri=https://www.dropbox.com/1/oauth2/display_token&client_id=66i4jqxfxns5vps"
                                        target="_blank"
                                        >link
                                    </a>
                                    per aprire la pagina dropbox
                                </li>
                                <!-- <li>Nella pagina dropbox cliccare accedi con google</li> -->
                                <li>inserire come email <strong>matrimonioalestella22@gmail.com</strong></li>
                                <li>inserire come password <strong>matrimonio2022</strong></li>
                                <li>Copiare e incollare il <strong>token</strong> dalla pagina di dropbox nella casella di testo</li>
                                <li>Cliccare <strong>Aggiorna</strong></li>
                            </ul>
                        </div>
                        <v-btn class="btnToken" color="success" @click="aggiornaToken">Aggiorna</v-btn>
                    </div>
                </div>
            </template>
            <template v-else>
                <v-alert v-if="uploadError" ref="alertImg" class="alert animate__animated animate__fadeInRight" type="error">Errore caricamento</v-alert>
                <div class="cntGallery">
                    <div class="cntUpload">
                        <v-dialog transition="dialog-top-transition" max-width="600">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn class="addImg" fab small dark color="black" v-bind="attrs" v-on="on">
                                    <v-icon dark>
                                        mdi-plus
                                    </v-icon>
                                </v-btn>
                            </template>
                            <template v-slot:default="dialog">
                                <v-card>
                                    <v-toolbar color="black" dark>Carica immagini</v-toolbar>
                                    <v-card-text class="mt-5">
                                        <v-file-input
                                            accept="image/png, image/jpeg, image/bmp"
                                            class="uploadImage"
                                            multiple
                                            v-model="imagesUpload"
                                            label="Carica una o più immagini"
                                            filled
                                            prepend-icon="mdi-camera"
                                            @change="addFiles"
                                            @click:clear="clear"
                                        ></v-file-input>
                                        <v-row>
                                            <v-col cl sm="3" v-for="(file, f) in imagesUpload" :key="f">
                                                <img ref="file" style=" object-fit: cover; width: 120px; height: 120px" :title="'file' + f" />
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                    <v-card-actions class="justify-end">
                                        <v-progress-circular v-if="loading" style="margin: 12px" :size="30" :width="4" color="black" indeterminate></v-progress-circular>
                                        <div v-else>
                                            <v-btn text @click="upload(dialog)">Carica</v-btn>
                                            <v-btn text @click="dialog.value = false">Annulla</v-btn>
                                        </div>
                                    </v-card-actions>
                                </v-card>
                            </template>
                        </v-dialog>
                    </div>
                    <div class="cntImages" :style="images.length == 0 ? 'justify-content: center' : ''">
                        <p v-if="images.length == 0" class="noImages">Nessun' immagine caricata.</p>
                        <img
                            v-else
                            class="img"
                            v-for="(image, index) in images"
                            :key="index"
                            :style="index == images.length - 1 ? 'justify-item: flex-start' : ''"
                            :src="image"
                            @click="imageViewer(index)"
                        />
                        <image-viewer-vue v-if="imageViewerFlag" @closeImageViewer="imageViewerFlag = false" :imgUrlList="images" :index="indexImg" :closable="true" :title="''" />
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import dropbox from "../scripts/dropbox";
import googleSheets from "../scripts/googleSheets";

export default {
    async mounted() {
        await this.getImages();
    },

    data() {
        return {
            images: [],
            imagesUpload: [],
            readers: [],
            uploadError: false,
            imageViewerFlag: false,
            indexImg: 0,
            loading: false,
            loadingToken: false,
            error: 0,
            token: "",
            rules: {
                required: [value => !!value || "Il campo è obbligatorio"]
            }
        };
    },

    methods: {
        async upload(dialog) {
            if (this.imagesUpload.length == 0) return;
            this.loading = true;
            for (var i = 0; i < this.imagesUpload.length; i++) {
                var reader = this.imagesUpload[i];
                var res = await dropbox.uploadPhoto(reader);
                if (!res) {
                    this.uploadError = true;
                    break;
                }
            }
            this.loading = false;
            if (this.uploadError) {
                setTimeout(() => {
                    this.$refs.alertImg.$el.classList.remove("animate__fadeInRight");
                    this.$refs.alertImg.$el.classList.add("animate__fadeOutRight");
                    setTimeout(() => {
                        this.uploadError = false;
                        this.$refs.alertImg.$el.classList.remove("animate__fadeInRight");
                        this.$refs.alertImg.$el.classList.add("animate__fadeOutRight");
                    }, 500);
                }, 4000);
                return;
            }
            this.images = await dropbox.getImages();
            (this.imagesUpload = []), (this.readers = []), (dialog.value = false);
        },

        addFiles() {
            this.imagesUpload.forEach((file, f) => {
                this.readers[f] = new FileReader(file);
                this.readers[f].onloadend = () => {
                    let fileData = this.readers[f].result;
                    let imgRef = this.$refs.file[f];
                    imgRef.src = fileData;
                    console.log(fileData);
                };

                this.readers[f].readAsDataURL(this.imagesUpload[f]);
            });
        },

        clear() {
            this.readers = [];
        },

        imageViewer(index) {
            this.imageViewerFlag = true;
            this.indexImg = index;
        },

        async aggiornaToken() {
            var validate = this.$refs.token.validate();
            if (!validate) return;
            this.loadingToken = true;
            await googleSheets.setToken(this.token);
            this.loadingToken = false;
            await this.getImages();
        },

        async getImages() {
            this.loadingToken = true;
            await dropbox.init();
            try {
                this.images = await dropbox.getImages();
                this.error = 0;
            } catch (err) {
                console.log(err.error[".tag"]);
                if (err.error[".tag"] == "expired_access_token") {
                    this.error = 401;
                }
            } finally {
                this.loadingToken = false;
            }
        }
    }
};
</script>

<style lang="scss">
.alert {
    position: fixed;
    right: 4px;
    top: 95px;
    z-index: 999;
}
.token {
    display: flex;
    flex-direction: column;
    width: 60%;
    .passaggiToken {
        display: flex;
        justify-content: space-between;
        .passaggi {
            margin-bottom: 4rem;
        }
    }
    .v-input__slot {
        background: white !important;
    }
}
.cntGallery {
    margin: 2rem 8px 0 8px;
    .cntUpload {
        display: flex;
        align-items: center;
        .addImg {
            position: fixed;
            bottom: 0;
            margin-bottom: 8px;
            right: 0;
            margin-right: 8px;
        }
        .uploadImage {
            width: 20%;
        }
        .stringInsImage {
            margin-bottom: 0;
            margin-left: 5px;
            color: white;
        }
    }
    .cntImages {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .img {
            object-fit: cover;
            width: 230px;
            height: 230px;
            margin: 10px;
            cursor: pointer;
        }
    }
}

.phone {
    .token {
        width: unset !important;
        .passaggiToken {
            flex-direction: column-reverse;
            .btnToken {
                align-self: flex-start;
            }
            .passaggi {
                margin-top: 10px;
            }
        }
    }
    .cntGallery {
        .cntImages {
            margin-bottom: 4rem;
            .img {
                width: 92px;
                height: 92px;
                margin: 2px;
            }
            @media (min-width: 360px) {
                .img {
                    width: 105px;
                    height: 105px;
                }
            }
            @media (min-width: 375px) {
                .img {
                    width: 110px;
                    height: 110px;
                }
            }
            @media (min-width: 412px) {
                .img {
                    width: 123px;
                    height: 123px;
                }
            }

            @media (min-width: 414px) {
                .img {
                    width: 123px;
                    height: 123px;
                }
            }

            @media (min-width: 667px) {
                .img {
                    width: 153px;
                    height: 153px;
                }
            }
        }
    }
}
</style>
