import { Dropbox, DropboxAuth } from "dropbox";
import googleSheets from "./googleSheets";

export default {
    dbx: null,

    async init() {
        var token = await googleSheets.getToken();
        var auth = new DropboxAuth({
            clientId: "i81thvhw94mwa31",
            clientSecret: "n2jsw5u41ceqhbu",
            accessToken: token
        });
        this.dbx = new Dropbox(auth);
    },

    async uploadPhoto(image) {
        try {
            await this.dbx.filesUpload({
                path: `/Matrimonio/${image.name}`,
                contents: image
            });
            return true;
        } catch (err) {
            console.log(err);
            return false;
        }
    },

    async getImages() {
        try {
            var images = [];
            var res = await this.dbx.filesListFolder({
                path: `/Matrimonio`
            });
            res.result.entries.forEach(async item => {
                var image = await this.getFile(item.path_display);
                images.push(image.result.link);
            });
            return images;
        } catch (err) {
            console.log(err);
            throw err.error;
        }
    },

    async getFile(path) {
        try {
            var res = await this.dbx.filesGetTemporaryLink({
                path: path
            });
            return res;
        } catch (err) {
            console.log(err);
            return false;
        }
    }
};
