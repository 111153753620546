<template>
    <div class="dediche" :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'phone' : ''">
        <div class="writeMessage">
            <v-textarea :class="{ textfield: message.text }" v-model="message.text" label="Lascia qui la tua dedica!" outlined clearable></v-textarea>
            <div class="cntBtns">
                <v-progress-circular v-if="loading" style="margin: 12px" :size="30" :width="4" color="black" indeterminate></v-progress-circular>
                <v-btn v-else class="btnInvia" color="success" @click="inviaCommento">Invia</v-btn>
            </div>
        </div>
        <div class="cntDediche">
            <p v-if="messaggi.length == 0" class="nessunaDedica">Nessuna dedica caricata</p>
            <div v-else class="cntDedica" v-for="(messaggio, index) in messaggi" :key="index">
                <p style="white-space: pre-line">{{ messaggio.Messaggio }}</p>
                <p class="data">{{ messaggio.Data }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import googleSheets from "../scripts/googleSheets";
export default {
    async mounted() {
        await this.getCommenti();
    },

    data() {
        return {
            message: {
                text: "",
                data: moment().format("DD/MM/YYYY HH:mm")
            },
            messaggi: [],
            loading: false
        };
    },

    methods: {
        async inviaCommento() {
            if(!this.message.text) return;
            this.loading = true;
            await googleSheets.addCommento(this.message);
            await this.getCommenti();
            this.loading = false;
            this.message = "";
        },

        async getCommenti() {
            this.messaggi = await googleSheets.getCommenti();
        }
    }
};
</script>

<style lang="scss">
.dediche {
    margin: 20px 8px;
    width: 60rem;
    .writeMessage {
        width: 100%;
        .v-input__slot {
            background: white !important;
        }
        .cntBtns {
            display: flex;
            justify-content: flex-end;
            .btnInvia {
                width: 6rem;
                align-self: flex-end;
            }
        }
    }

    .cntDediche {
        margin-top: 1.5rem;
        margin-bottom: 4rem;
        .nessunaDedica {
            text-align: center;
        }
        .cntDedica {
            background-color: white;
            padding: 0.5rem 0.5rem 0;
            display: flex;
            flex-direction: column;
            border-radius: 10px;
            margin: 1rem 0;
            p {
                margin-bottom: 0;
            }
            .data {
                align-self: flex-end;
                font-size: 10px;
            }
        }
    }
}
</style>
