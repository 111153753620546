<template>
    <div class="memories" :class="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm' ? 'phone' : ''">
        <div class="cntTabs">
            <p class="stringFoto">Carica le foto e i commenti più divertenti nel giorno del nostro si</p>
            <v-tabs v-model="tab" background-color="transparent" dark class="tabs">
                <v-tab v-for="item in itemsTab" :key="item">
                    {{ item }}
                </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
                <v-tab-item>
                    <div class="tabImmagini">
                        <Immagini />
                    </div>
                </v-tab-item>
                <v-tab-item>
                    <div class="tabDediche">
                        <Dediche />
                    </div>
                </v-tab-item>
            </v-tabs-items>
        </div>
    </div>
</template>

<script>
import Immagini from "../components/Immagini.vue";
import Dediche from "../components/Dediche.vue";
export default {
    data() {
        return {
            tab: null,
            itemsTab: ["Gallery", "Dediche"]
        };
    },

    components: {
        Immagini,
        Dediche
    }
};
</script>

<style lang="scss">
.memories {
    background: linear-gradient(180deg, rgb(129, 148, 255) 0%, rgb(166, 198, 245) 35%, rgb(191, 224, 255) 100%);
    height: 100%;
    .cntTabs {
        margin-top: 7rem;
        .stringFoto {
            text-align: center;
        }
        .v-slide-group__content {
            justify-content: center;
        }
        .theme--light.v-tabs-items {
            background: transparent !important;
        }

        .tabDediche {
            display: flex;
            justify-content: center;
        }
    }
}

.memories.phone {
    .cntTabs {
        margin: 5rem 8px 0 8px;
    }
}
</style>
